import React from "react"
import {StaticQuery, graphql} from "gatsby"

import Layout from "../components/ListLayout"
import SEO from "../components/seo"
import Post from "../components/Post"
import PaginationLinks from "../components/PaginationLinks"

const IndexPage = () => {
  const postPerPage = 10
  return (
    <Layout>
      <SEO title="首頁" />
      <StaticQuery
        query={indexQuery}
        render={data => {
          const numberOfPages = Math.ceil(
            data.allMarkdownRemark.totalCount / postPerPage
          )
          return (
            <div>
              {data.allMarkdownRemark.edges.map(({node}) => {
                const {
                  frontmatter: {title, date, author, tags, image},
                } = node

                return (
                  <Post
                    key={node.id}
                    title={title}
                    date={date}
                    author={author}
                    slug={node.fields.slug}
                    fluid={image?.childImageSharp?.fluid}
                    tags={tags}
                    showImg={false}
                  />
                )
              })}
              <PaginationLinks currentPage={1} numberOfPages={numberOfPages} />
            </div>
          )
        }}
      />
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {fields: {draft: {eq: false}}}
      sort: {fields: [frontmatter___date], order: DESC}
      limit: 10
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "YYYY-MM-DD ddd")
            author
            tags
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`

export default IndexPage
